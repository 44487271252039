import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from '../LocalizedLink';

export const FooterWrapper = styled.div`
 background: var(--color-black);  
 padding-bottom: 10px;
`;

export const FooterTop = styled.div`
  background-color: var(--color-black);
  color: var(--color-white);  
  padding: 6rem 0;
    p {
      margin: 1rem 0;
      font-size: 1.6rem;
      line-height: 160%;
    }

    a {
      color: var(--color-white);
      &:hover{
        color: var(--primary-color)
      }
    }  
`;

export const FooterBottom = styled.div`
  background-color: var(--primary-color);
  color: var(--color-white);  
  padding: 2rem 0;
`;

export const FooterContainer = styled.div`
  width: 100%;
  max-width: var(--width-container);
  margin: 0 auto;
  padding: 0 var(--space);
  ${media.greaterThan('large')`
    padding: 0 var(--space-sm);
  `}
`;

export const FooterColumns = styled.div`
  ${media.greaterThan('medium')`
      display: flex;
      margin: 0 -2rem;
  `}

`

export const FooterColumn = styled.div`
  ${media.greaterThan('medium')`
    flex: 1 1 0;
    margin: 0 2rem;
  `}  
`

export const ContactColumns = styled.div`
  ${media.greaterThan('large')`
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      `}

`

export const ContactColumn = styled.div`
  ${media.greaterThan('large')`
    padding-right: 4rem;
  `}  
`

export const FooterTitle = styled.h3`
  color: white;
  margin-top: 5rem;
  font-size: 2rem;
  font-weight: bold;
`;

export const FooterMoreLink = styled(LocalizedLink)`
  font-weight: bold;
  text-decoration: none;
`

export const LogoLink = styled(LocalizedLink)`
  display: block;
  width: 114px;
`;
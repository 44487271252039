import React from 'react';
import { useLocale } from '../../hooks/locale';
import icon from '../../images/icon-globe.svg';

import * as S from './styled';

const Languages = () => {
  // Grab the locale (passed through context) from the Locale Provider 
  // through useLocale() hook
  const { locale } = useLocale();

  return (
    <S.LanguageWrapper>

      <S.LanguageItem>
        <S.LanguageIcon src={icon} alt="Languages" />
      </S.LanguageItem>
      <S.LanguageItem>
        <S.LanguageLink 
          to="/" 
          // onClick={(e) => handleClickLanguage(e, "nl")}
          className={locale === 'nl' ? 'is-active' : ''}
        >
          NL
        </S.LanguageLink>
      </S.LanguageItem>      
      <S.LanguageItem>
        <S.LanguageLink 
          to="/en" 
          // onClick={(e) => handleClickLanguage(e, "en")}
          className={locale === 'en' ? 'is-active' : ''}
        >
          EN
        </S.LanguageLink>
      </S.LanguageItem>
    </S.LanguageWrapper>
  );
};

export default Languages;

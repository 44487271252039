import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from '../LocalizedLink';

export const HeaderWrapper = styled.div`
  ${media.greaterThan('large')`
  `}
`;

export const Headertop = styled.div`
  background-color: var(--primary-color);
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;  
`
export const HeaderBottom = styled.div`
  background-color: var(--color-black);
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: var(--width-container);
  margin: 0 auto;
  align-items: center;
  padding: 0 var(--space);

  ${media.greaterThan('large')`
    padding: 0 var(--space-sm);
  `}
`;

export const LogoLink = styled(LocalizedLink)`
  width: 90px;
`;

export const NavMenu = styled.div`
  width: 100%;
  display: none;
  ${media.greaterThan('medium')`;
    display: block;
  `}
  &.is-active {
    display: block;
  }
`;

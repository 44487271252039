import { createGlobalStyle } from 'styled-components';
import media from 'styled-media-query';

const GlobalStyles = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  html {
    font-size: 62.5%;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    box-sizing: border-box;
  }
  body {
    background: var(--body-bg);
    font-weight: 400;
    font-family: "Open Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: var(--text-color);
    font-size: 1.6rem;
    line-height: 180%;
    ${media.greaterThan('large')`
      font-size: 1.8rem;
      line-height: 180%;
    `}
  }
  img {
    display: block;
  	max-width: 100%;
  	height: auto;
  }

  h1,h2,h3,h4,h5,h6 {
    color: var(--color-black);
  }

  a {
    color: var(--link-color);
  }
  a:hover {
    color: var(--link-hover-color);
  }  

  :root { 
    --gray-extra-light: #f1f1f1; 
    --gray-light: #666666;  
    --gray: #475060;
    --gray-dark: #222;

    --color-white: #ffffff;
    --color-red: #e3000b;
    --primary-hover-color: #ce040d;
    --color-yellow: #F0D24A;
    --color-purple: #9D4CAD;
    --color-blue: #1E93CB;
    --color-blue-dark: #004480;
    --color-green: #10C06A;
    --hover-color-green: #0DA35A;
    --color-black: #000000;

    --primary-color: var(--color-red);
    --secondary-color: #2e333e;
    --thirdy-color: #001ff9;



    --body-bg: #FFFFFF; 

    --bg-light: var(--gray-extra-light);
    --bg-dark: var(--gray-dark);

    --border-light: var(--gray-light);
    --border-dark: var(--gray-dark);
    
    --link-color: var(--primary-color);
    --link-hover-color: var(--primary-hover-color);

    --text-color: var(--gray-dark);
    --text-light:var(--gray-light);
    --text-dark: var(--gray-dark);

    --width-container: 1180px;

    --space: 2rem;
    --space-sm: 1rem;
    --space-lg: 3rem;

  }
`;
export default GlobalStyles;

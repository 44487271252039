import React from 'react';
import useTranslations from '../useTranslations';
import Logo from '../Logo';
import NavigationFooter from '../NavigationFooter';

import * as S from './styled';

const Footer = () => {
  const {
    reportAfind,
    reportAfindText,
    reportAfindLinkText,
    reportAfindLinkUrl,
    home,
    telephone,
    poBox,
    country,
    university,
    email

  } = useTranslations();

  return (
    <S.FooterWrapper>
      <S.FooterTop>
        <S.FooterContainer>
          <S.LogoLink to="/" title={home} aria-label={home}>
            <Logo />
          </S.LogoLink>



          <S.FooterColumns>

              <S.FooterColumn>
                  <S.FooterTitle>
                    Contact
                  </S.FooterTitle>

                  <S.ContactColumns>
                    <S.ContactColumn>
                      <p>
                        Radboud {university} / Kunera <br/>
                        {poBox} 9102<br/>
                        6500 HD Nijmegen
                        { country  ? <><br/>{country}</>    :  null}         
                      </p>
                    </S.ContactColumn>
                    <S.ContactColumn>
                       <p>
                          Tel: {telephone} <br/>
                          {email}: <a href="mailto:kunera@let.ru.nl">kunera@let.ru.nl</a><br/>
                          Website: <a href="https://www.ru.nl/ckd/" rel="noopener noreferrer" target="_blank">https://www.ru.nl/ckd/</a>                          
                        </p>                       
                      </S.ContactColumn>
                  </S.ContactColumns>

  

                  


              </S.FooterColumn>

              
              <S.FooterColumn>
                <S.FooterTitle>
                  {reportAfind}
                </S.FooterTitle>
                <p>
                  {reportAfindText}
                </p>

                <S.FooterMoreLink to={reportAfindLinkUrl}>
                  {reportAfindLinkText} →
                </S.FooterMoreLink>                
              </S.FooterColumn>   

          </S.FooterColumns>





        </S.FooterContainer>
      </S.FooterTop>

      <S.FooterBottom>
        <S.FooterContainer>
          <NavigationFooter />
        </S.FooterContainer>
      </S.FooterBottom>



    </S.FooterWrapper>
  );
};

export default Footer;

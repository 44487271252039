import React from 'react';
import useMenuFooter from '../useMenuFooter';
import logo from '../../images/radboud-logo-white.svg';

import * as S from './styled';

const NavigationFooter = () => {
  const menuItems = useMenuFooter();

  return (
    <>
      <S.NavigationFooter>

        <S.NavigationFooterItems>
        {menuItems.map((menu, index) => (
          <S.NavigationFooterLink
            to={menu.link}
            aria-label={menu.name}
            activeClassName="active"
            key={`${menu.link}${index}`}
            >
            {menu.name}
          </S.NavigationFooterLink>
        ))}
        </S.NavigationFooterItems>

        <S.NavigationFooterButton href="https://www.ru.nl/ckd/" rel="noopener noreferrer" target="_blank">
          <img src={logo} alt="Radboud logo" />
        </S.NavigationFooterButton>

      </S.NavigationFooter>
    </>
  );
};

export default NavigationFooter;

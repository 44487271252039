import React from 'react';

import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/400-italic.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"

import GlobalStyles from '../styles/global';

import Header from '../components/Header';
import Footer from '../components/Footer';

import CookieConsent from 'react-cookie-consent';

import { useLocale } from '../hooks/locale';

import useTranslations from '../components/useTranslations';

import * as S from './styled';

const BaseLayout = ({ children, pageContext: { locale } }) => {
  // Using the useLocale() hook to define the correct locale 
  // that will be available in all components of the tree thought its context
  const { cookieConsent, cookieButton } = useTranslations();
  const { changeLocale } = useLocale();
  changeLocale(locale);

  return (
    <>
      <GlobalStyles />
      <S.Wrapper>
        <Header />
        <S.SiteContent role="main">
          {children}
        </S.SiteContent>
        <Footer />

        <CookieConsent
          location="bottom"
          style={{ color: "#000", backgroundColor: "rgba(255,255,255,0.9)", fontWeight: "bold", boxShadow: "0px 0px 15px 5px rgba(0,0,0,0.5)", display: "block", textAlign: "center", padding: "1rem"}}
          buttonStyle={{ backgroundColor: "#e3df00", fontWeight: "bold", color: "#000", fontSize: "16px", borderRadius: "22px", height: "44px", padding: "0 25px", display: "flex", alignItems: "center", alignContent: "center", marginLeft: "auto", marginRight: "auto" }}          
          buttonText={cookieButton}
          cookieName="gatsby-gdpr-google-tagmanager">
          {cookieConsent}
        </CookieConsent>

      </S.Wrapper>
    </>
  )
};

export { BaseLayout };

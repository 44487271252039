import styled from 'styled-components';
import { Link } from 'gatsby';

export const LanguageWrapper = styled.ul`
  display: flex;
  align-items: center;
`;

export const LanguageIcon = styled.img`
  opacity: 50%;
`;

export const LanguageItem = styled.li`
  border-right: 1px solid rgba(255,255,255,0.4);
  padding: 0 1rem;
  &:first-child,
  &:last-child{
    border: none;
    padding-right: 0
  }
`;

export const LanguageLink = styled(Link)`
  display: inline-block;
  color: var(--color-white);
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: bold;
  opacity: 60%;
  transition: .3s ease-in-out;
  &:hover{
    opacity: 100%;
    color: var(--color-white);
  }  
  &.is-active {
    font-weight: bold;
    opacity: 100%
  }
`;

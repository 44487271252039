import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from '../LocalizedLink';

export const NavigationFooter = styled.nav`
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  min-height: 90px;
  padding: 1rem 0;
  ${media.greaterThan('medium')`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const NavigationFooterItems = styled.div`
  text-align: center;
  border-bottom: 1px solid rgba(255,255,255,0.4);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  ${media.greaterThan('medium')`
    text-align: left;
    border: none;
    padding: 0;
    margin: 0
  `}
`

export const NavigationFooterLink = styled(LocalizedLink)`
  color: var(--color-white);
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  margin: 1rem;
  padding: 0;
  position: relative;
  text-align: center;
  ${media.greaterThan('medium')`
    font-weight: bold;
    margin: 0 40px 0 0;
    display: inline-block;
  `}

  &:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 2px;
      background: var(--color-white);
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
      transition: .3s ease-in-out;
  }

  &:hover,
  &.active {
    font-weight: bold;
    color: var(--color-white);
    &:after {
      opacity: 1;
      bottom: 0rem;
      width: 100%;
    }
  }
`;

export const NavigationFooterButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  color: #fff;
  text-decoration: none;
  text-align: center;
  img {
    height: 48px;
  }
`;

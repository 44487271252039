import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from '../LocalizedLink';

export const Navigation = styled.nav`
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  min-height: 90px;
  padding: 1rem 0;
  ${media.greaterThan('medium')`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const NavigationLink = styled(LocalizedLink)`
  color: var(--color-white);
  text-decoration: none;
  font-weight: normal;
  display: block;
  margin: 1rem 0;
  padding: 1rem 0;
  position: relative;
  text-align: center;
  ${media.greaterThan('medium')`
    font-weight: bold;
    margin-right: 40px;
    display: inline-block;
  `}

  &:after {
    ${media.greaterThan('medium')`
      content: '';
      display: inline-block;
      width: 0;
      height: 2px;
      background: var(--primary-color);
      position: absolute;
      left: 0;
      bottom: 0.75rem;
      opacity: 0;
      transition: .3s ease-in-out;
    `}
  }

  &:hover,
  &.active {
    font-weight: bold;
    color: var(--color-white);
    ${media.greaterThan('medium')`
      font-weight: bold;
    `}
    &:after {
      opacity: 1;
      bottom: 0.75rem;
      width: 100%;
    }
  }
`;

export const NavigationButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  padding: 0 2.5rem;
  height: 44px;
  border-radius: 22px;
  background: var(--color-green);
  color: var(--color-white);
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  transition: .3s ease-in-out;
  &:hover{
    background-color: var(--hover-color-green);
    color: var(--color-white);
  }  
`;

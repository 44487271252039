import React from 'react';
import useTranslations from '../useTranslations';
import Navigation from '../Navigation';
import Languages from '../Languages';
import ButtonMenu from '../ButtonMenu';
import Logo from '../Logo';

import { useMenu } from '../../hooks/menu';

import * as S from './styled';

const Header = () => {
  const { home } = useTranslations();
  const { openedMenu, toggleMenu } = useMenu();

  return (
    <S.HeaderWrapper>
      <S.Headertop>
        <S.Container>
          <ButtonMenu handleClick={toggleMenu} isActive={openedMenu} />
          <S.LogoLink to="/" title={home} aria-label={home}>
            <Logo />
          </S.LogoLink>
          <Languages />
        </S.Container>
      </S.Headertop>
      <S.HeaderBottom>
        <S.Container>  
          <S.NavMenu className={openedMenu ? 'is-active' : ''}>
            <Navigation />
          </S.NavMenu>
        </S.Container>
      </S.HeaderBottom>
    </S.HeaderWrapper>
  );
};

export default Header;

// Only one item MUST have the "default: true" key

module.exports = {
  en: {
    path: `en`,
    locale: `en-US`,
    dateFormat: `D MMMM YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Kunera`,
    defaultDescription: `Kunera`,
  },
  nl: {
    default: true,
    path: `nl`,
    locale: `nl-NL`,
    dateFormat: `D MMMM YYYY`,
    siteLanguage: `nl`,
    ogLanguage: `nl-NL`,
    defaultTitle: `Kunera`,
    defaultDescription: `Kunera`,
  },
}
